import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";

import { ToastContainer } from "react-toastify";

//const UserPages = lazy(() => import("../../DemoPages/UserPages"));
const Applications = lazy(() => import("../../DemoPages/Applications"));
const Dashboards = lazy(() => import("../../DemoPages/Dashboards"));

/*const Widgets = lazy(() => import("../../DemoPages/Widgets"));
const Elements = lazy(() => import("../../DemoPages/Elements"));
const Components = lazy(() => import("../../DemoPages/Components"));
const Charts = lazy(() => import("../../DemoPages/Charts"));
const Forms = lazy(() => import("../../DemoPages/Forms"));
const Tables = lazy(() => import("../../DemoPages/Tables"));*/


const AppMain = () => {

    return (
        <Fragment>
            {/* Applications */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse"/>
                        </div>
                        <h6 className="mt-3">
                            Por favor espere mientras se carga el portal
                            <small>Portal de servicios de soporte - UNOPS</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/apps" component={Applications}/>
            </Suspense>

            {/* Dashboards */}
            
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            Por favor espere mientras se carga el portal
                            <small>Portal de servicios de soporte - UNOPS</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/dashboards" component={Dashboards}/>
            </Suspense>
            

            <Route exact path="/" render={() => (
                <Redirect to="/dashboards/crm"/>
            )}/>
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;
